<template>
  <div v-if="hasResource('asset_management')">
    <div class="all-assets-container" @click="handleClose">
      <div class="search-form">
        <div class="dis-fix">
          <el-form :inline="true" :model="form">
            <el-form-item label="内部ID">
              <el-input
                v-model="form.assetsId"
                placeholder="输入内部ID"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="外部ID">
              <el-input
                v-model="form.asset_uid"
                placeholder="输入外部ID"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="资产名称">
              <el-input
                v-model="form.assetName"
                placeholder="输入名称"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="资产类型" class>
              <el-select v-model="form.assetsTypeValue">
                <el-option
                  v-for="item in assetsType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产分类">
              <el-cascader
                v-model="categoryValue"
                :props="defaultParams"
                :options="categoryOptions"
                ref="cascaderAddr"
                @change="handleChange"
                @remove-tag="removeTag"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="form.assetsStatusValue">
                <el-option
                  v-for="item in assetsStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="首字母" class>
              <el-select v-model="form.nameInitials">
                <el-option value label="全部"></el-option>
                <el-option
                  v-for="item in letters"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="颜色属性">
              <div class="color-name" @click.stop="handleShow($event)">
                <div v-if="form.colors.length === 0">请选择颜色属性</div>
                <div v-if="form.colors.length > 3" class="color-three-item">
                  <div
                    class="color-blockage"
                    :class="
                      form.colors[0] == '#FFFFFF'
                        ? 'color-item-border'
                        : 'color-border-eee'
                    "
                    :style="`background:${form.colors[0]}`"
                  >
                    <div
                      :class="form.colors[0] == '' ? 'item-none-slash' : ''"
                    ></div>
                  </div>
                  <div
                    :class="
                      form.colors[1] == '#FFFFFF'
                        ? 'color-item-border'
                        : 'color-border-eee'
                    "
                    class="color-blockage"
                    :style="`background:${form.colors[1]}`"
                  >
                    <div
                      :class="form.colors[1] == '' ? 'item-none-slash' : ''"
                    ></div>
                  </div>
                  <div class="color-blockage-over-three">
                    <div class="color-blockage-dot"></div>
                    <div class="color-blockage-dot"></div>
                    <div class="color-blockage-dot"></div>
                  </div>
                </div>
                <div
                  v-else
                  class="width-30 height-30 color-number"
                  v-for="(item, index) in form.colors"
                  :key="index"
                  :style="`background:${item}`"
                  :class="
                    item == '#FFFFFF' ? 'color-item-border' : 'color-border-eee'
                  "
                >
                  <div :class="item == '' ? 'item-none-slash' : ''"></div>
                </div>
                <div class="color-icon-down">
                  <i v-if="!colorShow" class="el-icon-arrow-down"></i>
                  <i v-if="colorShow" class="el-icon-arrow-up"></i>
                </div>
              </div>
              <div
                class="color-items"
                :class="!colorShow ? 'display-none' : 'display-block'"
              >
                <div class="color-bor-items">
                  <div
                    class="width-30 height-30 color-child-content"
                    v-for="(item, index) in colors"
                    :key="index"
                    :style="`background:${item === '' ? '#FFFFFF' : item}`"
                    @click.stop="chooseColor(item, index, $event)"
                    :class="
                      item === '' && form.colors.includes(item)
                        ? 'bor-red'
                        : form.colors.includes(item) &&
                          item !== '' &&
                          item != '#FFFFFF'
                        ? 'bor-white'
                        : item == '#FFFFFF' && form.colors.includes(item)
                        ? 'bor-black'
                        : ''
                    "
                  >
                    <div :class="item == '' ? 'color-none-slash' : ''"></div>
                    <i
                      v-if="form.colors.includes(item) && item !== ''"
                      class="iconfont icon-position color-index"
                      :class="
                        item == '#FFFFFF' || item == '' ? 'black' : 'white'
                      "
                      >&#xe607;</i
                    >
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
          <div class="text-btn">
            <span class="serach-button" @click="inquire">查询</span>
            <span class="clear-button" @click="reset">清空</span>
          </div>
        </div>
      </div>
      <el-table
        :data="assetsData"
        style="width: 100%"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="isTableLoading"
        @sort-change="sortChange"
      >
        <el-table-column
            v-for="col in tableColumns"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :align="col.align"
            :fixed="col.fixed">
            <template slot-scope="{row}">
                <div v-if="col.prop=== 'preview'">
                  <img :src="row.preview + '?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1'" class="max-height-64 max-width-64" />
                </div>
                <div v-else-if="col.prop === 'categories'">
                    <span v-if="row.categories.length === 0">--</span>
                    <span v-for="(item, index) in row.categories" :key="index">
                    {{ item.label }}
                    <span v-if=" row.categories.length > 1 && row.categories.length - 1 !== index ">,</span>
                    </span>
                </div>
                <div v-else-if="col.prop === 'status'">
                    <!-- <span v-if="row.status == 0">未下载</span> -->
                    <span v-if="row.status == 1">未核验</span>
                    <span v-if="row.status == 2 || row.status == 0">已核验</span>
                    <!-- status：3、4 对应审核中 -->
                    <span v-if="row.status == 3">未审核</span>
                    <span v-if="row.status == 4">已审核</span>
                    <span v-if="row.status == 5">已发布</span>
                    <span v-if="row.status == 6">重制中</span>
                    <span v-if="row.status == 99">已作废</span>
                </div>
                <div v-else-if="col.prop === 'color'">
                    <div v-if="row.color.length === 0">--</div>
                    <div
                        v-for="(item, index) in row.color"
                        :key="index"
                        class="color-contents"
                        :style="`background:${item}`"
                        :class="item === '#FFFFFF' ? 'border-1-black' : ''">
                    <div class="display-none">
                        {{ item }}
                        <span v-if=" row.color.length > 1 && row.color.length - 1 !== index">,</span>
                    </div>
                    </div>
                </div>
                <div v-else-if="col.prop === 'operation'" class="icon-btn">
                    <div class="icon-btn">
                        <el-button
                            size="mini"
                            type="text"
                            class="preview-color"
                            @click="handleSee(row)"
                            v-if="hasResource('asset_info')" >
                            <i class="iconfont">&#xe662;</i>查看
                        </el-button>
                        <el-button
                            class="preview-color"
                            size="mini"
                            type="text"
                            @click="updateAssets(row)"
                            v-if="
                            (row.status == 0) &&
                                hasResource('asset_update')">
                            <i class="iconfont">&#xe666;</i>重传
                        </el-button>
                        <el-button
                            class="preview-color"
                            size="mini"
                            type="text"
                            @click="withDraw(row)"
                            v-if="
                            (row.status == 3 || row.status == 4) &&hasResource('asset_cancel_review')">
                            <i class="iconfont">&#xe65d;</i>撤回
                        </el-button>
                        <el-button
                            class="preview-color"
                            size="mini"
                            type="text"
                            v-if="row.status == 0 && hasResource('asset_download')"
                            @click="handleDownload(row)"
                            :loading="row.isLoading">
                            <i class="iconfont">&#xe65e;</i>下载
                        </el-button>
                        <el-button
                            class="preview-color"
                            size="mini"
                            type="text"
                            v-if="
                            row.status == 2 && hasResource('asset_publish_review')
                            "
                            @click="publishAsset(row)">
                            <i class="iconfont">&#xe661;</i>发布
                        </el-button>
                        <el-button
                            @click="verifyAssets(row)"
                            class="preview-color"
                            size="mini"
                            type="text"
                            v-if="row.status == 1 && hasResource('asset_verify')">
                            <i class="iconfont">&#xe65c;</i>核验
                        </el-button>
                        <!-- <el-button
                            class="preview-color"
                            size="mini"
                            type="text"
                            @click="deleteAssets(row)"
                            v-if="
                            (row.status == 0 ||
                                row.status == 1 ||
                                row.status == 2 ||
                                row.status == 6) &&
                                hasResource('asset_discard') ">
                            <i class="iconfont">&#xe678;</i>移出
                        </el-button> -->
                        <el-button
                            class="preview-color"
                            size="mini"
                            type="text"
                            @click="handleDestock(row)"
                            v-if="row.status == 5 && hasResource('asset_remove')">
                            <i class="iconfont">&#xe660;</i>下架
                        </el-button>
                        <!-- <el-button
                            class="preview-color"
                            size="mini"
                            type="text"
                            v-if="row.status == 99 && hasResource('asset_remake')"
                            @click="handleRemake(row)">
                            <i class="iconfont">&#xe612;</i>重制
                        </el-button> -->
                    </div>
                </div>
                <div v-else>{{row[col.prop] || '--'}}</div>
            </template>
        </el-table-column>
      </el-table>
      <div class="ta-r mg-t-18">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          v-if="total != 0"
        ></el-pagination>
      </div>

      <!-- 上传 -->

      <!-- 更新提示弹框 -->
      <el-dialog
        top="15%"
        :visible.sync="dialogVisibleUpdate"
        width="458px"
        class="add-dialog"
        :close-on-click-modal="false">
        <div class="dialog-content">
          <div class="dialog-title">
            您将更新资产
            <span>{{ assetsName }}</span>
          </div>
          <div class="dialog-dec">
            成功上传后，新的数据将覆盖旧的，系统自动发起变更审批流程。
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-upload
            class
            action="#"
            :http-request="handleSuccess"
            :show-file-list="false"
            :before-upload="onChange"
          >
            <el-button
              class="up-button"
              @click="submit"
              v-loading="uploadLoading"
              >上传</el-button
            >
          </el-upload>
          <!-- <el-button @click="update" class="">上传</el-button> -->
          <el-button @click="dialogVisibleUpdate = false" class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>

      <!-- 核验提示弹框 -->
      <el-dialog
        top="15%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleVerify"
        width="458px"
        class="add-dialog">
        <div class="dialog-content">
          <div class="dialog-title">
            您将核验资产
            <span>{{ assetsName }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            class="up-button"
            @click="assetsverify"
            v-loading="verifyLoading"
            >确认</el-button
          >
          <el-button @click="dialogVisibleVerify = false" class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>

      <!-- 撤回提示弹框 -->
      <el-dialog
        top="15%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleWithdraw"
        width="458px"
        class="add-dialog">
        <div class="dialog-content">
          <div class="dialog-title">
            您将撤回已发布资产
            <span>{{ assetsName }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            class="up-button"
            @click="withDrawSure"
            :loading="withdrawLoading"
            >确认</el-button
          >
          <el-button
            @click="dialogVisibleWithdraw = false"
            class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>

      <!-- 作废提示弹框 -->
      <!-- <el-dialog
        top="15%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleDelete"
        width="458px"
        class="add-dialog">
        <div class="dialog-content">
          <div class="dialog-title">
            您将移出资产
            <span>{{ assetsName }}</span>
            <br/>
            移出后该资产不可在‘全部资产’中展示
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="up-button" @click="deleteSure" :loading="delLoading"
            >确认</el-button
          >
          <el-button @click="dialogVisibleDelete = false" class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog> -->

      <!-- 重置提示弹框 -->
      <el-dialog
        top="15%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleRemake"
        width="458px"
        class="add-dialog" >
        <div class="dialog-content">
          <div class="dialog-title">
            您将重置资产
            <span>{{ assetsName }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            class="up-button"
            @click="remakeSure"
            :loading="remakeLoading"
            >确认</el-button
          >
          <el-button @click="dialogVisibleRemake = false" class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 资产下架 -->
    <remove-dialog ref="destockPop" :updateAssetList="init"></remove-dialog>
  </div>
</template>

<script>
import RemoveDialog from "../marketAssets/mkt-components/dialogs/rm-dialog";
import api from "../../../api/assets";
import assetImport from "../../../mixins/asset/import";
import classifyFilter from '@/mixins/asset/classify.js';
export default {
  name: "allAssets",
  components: { RemoveDialog },
  mixins: [assetImport, classifyFilter],
  data() {
    return {
    tableColumns:[
        { prop: "preview", label: "缩略图", width: 190, align: "left" , fixed: "left" },
        { prop: "origin_id", label: "内部ID", width: 170, align: "left" },
        { prop: "asset_uid", label: "外部ID", width: 180, align: "left" },
        { prop: "name",  label: "英文名称", width: 180, align: "left" },
        { prop: "chinese_name",  label: "中文名称", width: 180, align: "left" },
        { prop: "type", label: "资产类型", width: 120, align: "left" },
        { prop: "categories", label: "资产分类", width: 220, align: "left" },
        { prop: "status", label: "状态", width: 120, align: "left" },
        { prop: "color", label: "颜色属性", width: 155, align: "left" },
        { prop: "operation", label: "操作", width: 380, align: "left",fixed:"right"},
    ],
      uploadLoading: false,
      colorShow: false,
      hasColor: false,
      isLoading: false,
      pagesize: 10,
      total: 0,
      currentPage: 1,
      order_by: "updated_at",
      sort: "DESC",
      header: {
        url: "allAssets",
      },
      // 上传数据
      action: "",
      form: {
        assetName: "",
        asset_uid: "",
        assetsId: "",
        assetsName: "",
        assetsStatusValue: "",
        categoryValue: [],
        assetsTypeValue: "",
        nameInitials: "",
        colors: [],
      },
      // 搜索信息数据
      assetName: "",
      assetsId: "",
      assetsName: "",
      assetsValue: "",
      assetsStatusValue: "",
      assetsStatus: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "2",
          label: "已核验",
        },
        {
          value: "3",
          label: "未审核",
        },
        {
          value: "4",
          label: "已审核",
        },
        {
          value: "5",
          label: "已发布",
        },
        // {
        //   value: "6",
        //   label: "重制中",
        // },
        {
          value: "99",
          label: "已作废",
        },
      ],
      assetsType: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "4",
          label: "3D Assets",
        },
        {
          value: "1",
          label: "Surface",
        },
        {
          value: "3",
          label: "Decals",
        },
      ],
      // 表格数据
      assetsData: [],
      isTableLoading: false,
      fileList: [],
      file: {},
      categoryValue: [],
      categoryOptions: [],
      defaultParams: {
        label: "name_en",
        value: "id",
        children: "child",
        multiple: true,
        // checkStrictly:true
      },
      // 更新弹框
      dialogVisibleUpdate: false,
      // 核验弹框
      dialogVisibleVerify: false,
      // 撤回弹框
      dialogVisibleWithdraw: false,
      // 删除弹框
      dialogVisibleDelete: false,
      // 重置弹框
      dialogVisibleRemake: false,
      ids: "",
      deleteId: "",
      verifyLoading: false,
      assetNameId: "",
      letters: [],
      remakeId: "",
      recordDatd: {},
      colors: [
        "",
        "#B8342B",
        "#FC6B33",
        "#F2CE58",
        "#519662",
        "#1E3783",
        "#593562",
        "#242424",
        "#FFFFFF",
        "#ABAEAA",
      ],
      categoriesValue: [],
      changeTrue: false,
      withdrawLoading: false,
      delLoading: false,
      remakeLoading: false,
    };
  },
  methods: {
    addByEnterKey(e) {
      if (e.keyCode == 13) {
        this.inquire();
      }
    },
    handleDestock(row) {
      this.$nextTick(() => {
        this.$refs["destockPop"].show(row);
      });
    },
    handleSizeChange(val) {
      this.getUserList();
      this.parameters = false;
      this.pagesize = val;
    },
    handleCurrentChange(val) {
      this.parameters = false;
      this.currentPage = val;
      // this.getUserList();
      if (
        val !== 1 &&
        this.form.assetName !== "" &&
        this.form.asset_uid !== "" &&
        this.form.assetsId !== "" &&
        this.form.assetsStatusValue !== "" &&
        this.form.assetsTypeValue !== "" &&
        this.form.categoryValue !== [] &&
        this.form.nameInitials !== ""
      ) {
        this.inquire();
      } else {
        this.getUserList();
      }
    },
    // 重置
    reset() {
      this.form.assetsId = "";
      this.form.asset_uid = "";
      this.form.assetName = "";
      this.categoryValue = [];
      this.form.colors = [];
      this.form.assetsStatusValue = "";
      this.form.assetsTypeValue = "";
      this.form.nameInitials = "";
      this.recordDatd = {};
      this.form.categoryValue = [];
      // this.getUserList();
    },
    // 查询
    inquire() {
      var strArr = this.form.assetName.split(""); //将字符串进行拆分
      var reg = /^[A-Z]+$/; //正则A-Z
      let newStr = "";
      for (let i = 0; i < this.form.assetName.length; i++) {
        if (reg.test(strArr[i]) && i != 0 && strArr[i - 1] !== " ") {
          strArr[i] = " " + strArr[i];
        }
        newStr += strArr[i];
      }
      this.assetNameId = newStr;
      this.currentPage = 1;
      this.getUserList();
    },
    getUserList() {
      let data = {
        limit: this.pagesize,
        order_by: this.order_by,
        page: this.currentPage,
        sort: this.sort,
        filter: {
          origin_id:
            this.form.assetsId /* ||(this.recordDatd.asset_id && this.recordDatd.asset_id)*/,
          name:
            this.assetNameId ||
            this.form.assetName /*|| this.recordDatd.asset_name*/,
          asset_uid: this.form.asset_uid /* || this.recordDatd.asset_uid*/,
          // name: this.form.assetName,
          status: this.form.assetsStatusValue,
          category_id: this.form.categoryValue /*|| this.recordDatd.asset_category*/,
          type_ids: [this.form.assetsTypeValue /*|| this.recordDatd.asset_type*/],
        //   type: this.form.assetsTypeValue || this.recordDatd.asset_type,
          asset_initials:
            this.form.nameInitials /*|| this.recordDatd.asset_nameInitials*/,
          color: this.form.colors ? this.form.colors : "",
        },
        search: "",
        market: "US",
      };
      this.isTableLoading = true;
      api.assetsList(data).then((res) => {
        if (res.data.code === 10200) {
          this.assetsData = res.data.data.items;
          this.total = res.data.data.total;
          if (this.assetsData.length <= 0 && this.currentPage > 1) {
            this.currentPage = 1;
            this.getUserList();
          }
        } else {
          this.$$error(res.data.message);
        }
        this.isTableLoading = false;
      });
    },
    updateFilterParams() {
      this.recordDatd = this.getLocalCurrentPage("assetList0415");
      let params = this.recordDatd;
      if (params) {
        this.parameters = true;
        this.pagesize = params ? params.limit : this.pagesize;
        this.currentPage = params ? params.page : this.currentPage;
        this.form.assetsId = params ? params.asset_id : this.form.assetsId;
        this.form.assetNameId = params
          ? params.assetName
          : this.form.assetNameId;
        this.form.asset_uid = params ? params.asset_uid : this.form.asset_uid;
        this.form.assetsStatusValue = params
          ? params.asset_status
          : this.form.assetsStatusValue;
        this.form.categoryValue = params
          ? params.asset_category
          : this.form.categoryValue;
        this.form.type = params ? params.asset_type : this.form.type;
        this.form.nameInitials = params
          ? params.asset_nameInitials
          : this.form.nameInitials;
        this.getUserList();
      }
    },
    async init() {
      await this.categoty();
      await this.getAllLetters();
      if (this.getLocalCurrentPage("needUpdate")) {
        await this.updateFilterParams();
        await this.getUserList();
        this.recordCurrentPage("needUpdate", false);
      } else {
        await this.getUserList();
      }
    },
    // 分类
    categoty() {
      api.categoryList().then((res) => {
        if (res.data.code === 10200) {
          this.categoryOptions = this.handleFilterData(res.data.data.items);
        }
      });
    },
    handleQuestion() {},
    submit() {},
    handleChange(val) {
      if (this.changeTrue) {
        this.changeTrue = false;
        return;
      }
      this.form.categoryValue = [];
      this.$refs.cascaderAddr.getCheckedNodes().forEach((item, index) => {
        if (item.checked == true) {
          this.form.categoryValue.push(item.value);
        }
      });
      this.form.categoryValue = Array.from(new Set(this.form.categoryValue));
    },
    removeTag(val) {
      this.changeTrue = true;
      this.$refs.cascaderAddr.getCheckedNodes().forEach((item, index) => {
        val.forEach((el) => {
          this.form.categoryValue.forEach((e, index) => {
            if (el === e) {
              this.form.categoryValue.splice(index, 1);
            }
          });
        });
      });
    },
    // 更新弹框
    updateAssets(row) {
      this.dialogVisibleUpdate = true;
      this.assetsName = row.name;
      this.originId = row.origin_id;
    },
    update() {},
    // 核验弹框
    verifyAssets(row) {
      this.assetsName = row.name;
      this.dialogVisibleVerify = true;
      this.ids = row.id;
    },
    assetsverify() {
      this.verifyLoading = true;
      let data = {
        market: "US",
        id: this.ids,
      };
      api.verify(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogVisibleVerify = false;
          this.getUserList();
        } else {
          this.$$error(res.data.message);
        }
        this.verifyLoading = false;
      });
    },
    // 撤回弹框
    withDraw(row) {
      this.assetsName = row.name;
      this.dialogVisibleWithdraw = true;
      this.withDrawId = row.id;
    },
    withDrawSure() {
      this.withdrawLoading = true;
      let data = {
        market: "US",
        id: this.withDrawId,
        comment: "",
      };
      api.withDraw(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogVisibleWithdraw = false;
          this.$$success(res.data.message);
          this.getUserList();
        } else {
          this.$$error(res.data.message);
        }
        this.withdrawLoading = false;
      });
    },
    // 删除弹框
    // deleteAssets(row) {
    //   this.assetsName = row.name;
    //   this.dialogVisibleDelete = true;
    //   this.deleteId = row.id;
    // },
    // deleteSure() {
    //   this.delLoading = true;
    //   let data = {
    //     id: this.deleteId,
    //     market: "US",
    //   };
    //   api.assetDelete(data).then((res) => {
    //     if (res.data.code === 10200) {
    //       this.dialogVisibleDelete = false;
    //       this.getUserList();
    //     }
    //     if (res.data.code === 10001) {
    //       this.$$error(res.data.message);
    //       this.dialogVisibleDelete = false;
    //     }
    //     this.delLoading = false;
    //   });
    // },
    // 预览
    handleSee(row) {
      this.$router.push({
        path: "/asset",
        query: {
          id: row.id,
        },
      });
      const params = {
        limit: this.pagesize,
        page: this.currentPage,
        asset_id: this.form.assetsId,
        asset_uid: this.form.asset_uid,
        asset_name: this.form.assetName,
        asset_type: this.form.assetsTypeValue,
        asset_category: this.form.categoryValue,
        asset_status: this.form.assetsStatusValue,
        asset_nameInitials: this.form.nameInitials,
      };
      this.recordCurrentPage("assetList0415", params);
    },
    // 下载
    handleDownload(row) {
      this.$set(row, "isLoading", true);
      let data = {
        id: row.id,
        compressed: 1,
        market: "US",
      };
      api.download(data).then((res) => {
        if (res.data.code === 10200) {
          this.downloadFile(res.data.data.data.url[0]);
          this.$nextTick(() => {
            this.$$success("已下载");
          });
          this.getUserList();
        }
        if (res.data.code === 10001) {
          this.$$error(res.data.message);
        }
        this.$set(row, "isLoading", false);
      });
    },
    // 发布
    publishAsset(row) {
      this.$router.push({
        path: "/asset/publish",
        query: {
          id: row.id,
        },
      });
    },
    sortChange(column, prop, order) {
      this.order_by = column.prop;
      this.sort = column.order == "descending" ? "DESC" : "ASC";
      this.getUserList();
    },

    // 重置
    handleRemake(row) {
      this.dialogVisibleRemake = true;
      this.assetsName = row.name;
      this.remakeId = row.id;
    },
    remakeSure() {
      this.remakeLoading = true;
      let data = {
        market: "US",
        id: this.remakeId,
      };
      api.remake(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogVisibleRemake = false;
          this.getUserList();
        } else {
          this.$$error(res.data.message);
          this.dialogVisibleRemake = false;
        }
        this.remakeLoading = false;
      });
    },
    handleShow() {
      this.colorShow = !this.colorShow;
    },
    chooseColor(color, indexs) {
      if (this.form.colors.includes(color)) {
        this.form.colors.forEach((item, index) => {
          if (item == color) {
            this.form.colors.splice(index, 1);
          }
        });
        return;
      }
      this.hasColor = !this.hasColor;
      this.form.colors.push(color);
      this.form.colors = Array.from(new Set(this.form.colors));
    },
    handleColor(color) {},
    handleClose() {
      this.colorShow = false;
    },
  },
  mounted() {
    this.msgBus.$on("assetList", () => {
      this.recordCurrentPage("needUpdate", true);
    });
    this.init();
  },
  activated() {
    if (this.$route.meta.keepAlive) {
      this.init();
    }
  },
};
</script>

<style lang="less">
@import "./scss/index.less";
</style>

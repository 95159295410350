import api from "../../api/assets";
const mixin = {
  data() {
    return {
      status: "",
      label: "",
      importLoading: false,
      uploadLoading: false,
      disabled: false,
    };
  },
  methods: {
    // 上传文件类型限制
    onChange(file) {
      this.files = file;
      const extension = file.name.split(".")[1] === "xls";
      const extension2 = file.name.split(".")[1] === "xlsx";
      if (!extension && !extension2) {
        this.$message.warning("上传文件只能是 xls、xlsx格式!");
        return false;
      }
    },
    handleSuccess(item) {
      this.disabled = true;
      var formData = new FormData();
      formData.append("market", "US");
      formData.append("file", item.file);
      formData.append("origin_id", this.originId);
      let uploadGif = require("../../assets/images/loading.gif");

      if (this.originId === "") {
        let upload = this.$message({
          message:
            '<div style="margin-left:10px;"><img src="' +
            uploadGif +
            '" width=16px height=16px><div style="display:inline-block;margin-left:20px;color:white;font-size:16px;">正在上传数据，请稍等</div></div>',
          type: "info",
          dangerouslyUseHTMLString: true,
          duration: 0,
        });
        this.importLoading = true;
        api.import(formData).then((res) => {
          if (res.data.code === 10200) {
            this.dialogVisibleUpdate = false;
            upload.close();
            this.$message.success({
              message: "导入成功" + res.data.data.success_total + "条",
              duration: 0,
              showClose: true,
            });
            this.init();
          } else if (res.data.code === 10011) {
            let successTotal = res.data.data.success_total
              ? res.data.data.success_total
              : 0;
            let failTotal = res.data.data.fail_total
              ? res.data.data.fail_total
              : 0;
            let failReport = res.data.data.fail_report
              ? res.data.data.fail_report
              : "#";
            upload.close();
            this.$message({
              showClose: true,
              message:
                "导入成功" +
                successTotal +
                "条," +
                "失败" +
                failTotal +
                "条" +
                '<a href="' +
                failReport +
                '" class="question">查看失败原因</a>',
              type: "error",
              dangerouslyUseHTMLString: true,
              duration: 0,
            });
            this.dialogVisibleUpdate = false;
            this.disabled = false;
          } else if (res.data.code === 10003) {
            upload.close();
            this.$$error(res.data.message);
          } else {
            upload.close();
            this.$$error("网络不稳定，请稍后再试");
          }
          this.importLoading = false;
          this.originId = "";
        });
      } else {
        this.uploadLoading = true;
        api.update(formData).then((res) => {
          if (res.data.code === 10200) {
            this.dialogVisibleUpdate = false;
            // upload.close()
            this.$$success("导入成功" + res.data.data.success_total + "条");
            this.init();
          } else if (res.data.code === 10011) {
            if (
              res.data.data.success_total === 0 &&
              res.data.data.fail_total === 0
            ) {
              // upload.close()
              this.$$error(res.data.message);
              this.dialogVisibleUpdate = false;
            } else {
              let successTotal = res.data.data.success_total
                ? res.data.data.success_total
                : 0;
              let failTotal = res.data.data.fail_total
                ? res.data.data.fail_total
                : 0;
              let failReport = res.data.data.fail_report
                ? res.data.data.fail_report
                : "#";
              // upload.close()
              this.$message({
                showClose: true,
                message:
                  "导入成功" +
                  successTotal +
                  "条," +
                  "失败" +
                  failTotal +
                  "条" +
                  '<a href="' +
                  failReport +
                  '" class="question">查看失败原因</a>',
                type: "error",
                dangerouslyUseHTMLString: true,
                duration: 10000,
              });
              this.dialogVisibleUpdate = false;
            }
          } else if (res.data.code === 10003) {
            this.$$error(res.data.message);
          } else {
            // upload.close()
            this.$$error("网络不稳定，请稍后再试");
          }
          this.uploadLoading = false;
          this.originId = "";
          this.disabled = false;
        });
      }
    },
  },
};

export default mixin;
